function navigationOverlayToggle() {
  const menuButton = document.querySelector("#navigation__top-bar-open");
  const menuClose = document.querySelector("#navigation__top-bar-close");
  const menuOverlay = document.querySelector("#navigation__top-bar-overlay");
  const links = document.querySelectorAll(".navigation-link");

  if (!menuButton) {
    return;
  }

  menuButton.addEventListener("click", () => {
    menuOverlay.classList.remove("invisible");
    menuOverlay.classList.add("visible");
  });

  [...links, menuClose].forEach(item => {
    item.addEventListener("click", () => {
      menuOverlay.classList.remove("visible");
      menuOverlay.classList.add("invisible");
    });
  })

}

export default navigationOverlayToggle;
