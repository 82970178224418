function gallerySlider() {
    var elem = document.querySelector('.main-carousel');
    var flkty = new Flickity(elem, {
        cellAlign: 'left',
        contain: true
    });


}

export default gallerySlider;